@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: Inter;
}

a{
    text-decoration: none;
}
  

.center-display {
    width: 50%;
    margin: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
}

.line-logo {
    width: 90%;
    height: 6.5rem;
    align-items: end;
    text-align: start;
    margin-top: 1rem;
}

.line-logo img {
    height: 5rem;

}

.line-title h1 {
    margin-top: auto;
    margin-bottom: auto;
    width:90%;
}



.line-title {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.content-live {
    width: 864px;
    background-color: black;
    height: 486px;
    border-radius: 15px;
    overflow: hidden;
    pointer-events: visibleFill;
}

.sub-content {
    margin-top: 0.75rem;
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
}

#text{
    margin-left: 1rem;
    font-size: 12pt;
    font-weight: 500;
}

.full {
    width: 8rem;
    height: 70%;
    background: rgb(240, 240, 240);
    border: 0.5px rgb(207, 207, 207) solid;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.012);
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;


}

.full-cct p {
    margin-top: 0.45rem;
    margin-bottom: auto;
    font-size: 11pt;
}

.content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    width: 100%;
}

.content p{
font-size: 11pt;
}

.full-cct {
    display: flex;
    flex-direction: row;
    height: 90%;
    width: 7rem;
    align-items: center;
    margin: auto;
    padding-left: 0.70rem;
}


.full-cct svg {
    padding-top: 2px;
    margin-left: 0.75rem;
    margin-top: auto;
    margin-bottom: auto;
}

#circle{
    height: 17px;
    width: 17px;
    background-color: red;
    border-radius: 100%;
    box-shadow: 0px 0px 10px 10px rgba(255, 0, 0, 0.153) ;
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: Inter;
}

.acessar{
    display: flex;
    margin-top: 0.5rem;
    background-color: red;
    width: 11rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 100px;
}

.acessar p{
    color: white;
    margin: auto;
    font-weight: bold;
    font-size: 11pt;
}
#par{
    color: rgb(88, 88, 88);
    margin-top: 2rem;
}
/* Responsividade */
/* Responsividade */
@media (max-width: 1200px) {
    .center-display {
        width: 70%;
    }
    .content{
        width: 80%;
    }
}

@media (max-width: 992px) {
    .center-display {
        width: 90%;
    }
    .content-live {
        width: 600px;
        height: 338px;
    }
}

@media (max-width: 768px) {
    .center-display{
        width: 90%;
        height: 99vh;
    }
    .content {
        width: 98%;
        height: 100%;
        margin-top: 2rem;
    }
    .content-live {
        width: 100%;
        height: auto;
    }
    .line-logo, .line-title, .sub-content {
        width: 100%;
    }
    #text {
        font-size: 10pt;
    }
    .full {
        width: 6rem;
    }
    .full-cct p {
        font-size: 9pt;
    }
}

@media (max-width: 576px) {
    .center-display{
        width: 90%;
        height: 99vh;
    }
    .content {
        width: 98%;
        height: 100%;
        margin-top: 2rem;
    }
    .full {
        width: 5rem;
    }
    .full-cct p {
        font-size: 8pt;
    }
}
